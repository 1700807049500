<template>
  <div class="container-title">
    <div class="title">
      <div class="number">4</div>
      <div class="moon">
        <div class="face">
          <div class="mouth"></div>
          <div class="eyes">
            <div class="eye-left"></div>
            <div class="eye-right"></div>
          </div>
        </div>
      </div>
      <div class="number">4</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Animation"
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Lato|Russo+One');

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}


//text and moon
.container-title {
  user-select: none;
  width: 600px;
  height: 450px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  color: white;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  @media screen and (max-width: 1400px) {
      transform:translate(-50%, -50%) scale(0.8);
  }
}

.title > * {
  display: inline-block;
  font-size: 200px;
}

.number {
  text-shadow: 20px 20px 20px rgba(0, 0, 0, 0.2);
  padding: 0 0.2em;
  font-family: 'Russo One', sans-serif;
}

.moon {
  position: relative;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff,
  0 0 70px #fff, 0 0 80px #fff, 0 0 100px #ff1177;
  animation: rotate 5s ease-in-out infinite;

  .face {
    top: 60%;
    left: 47%;
    position: absolute;

    .mouth {
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-top-right-radius: 50%;
      background-color: #5c3191;
      width: 25px;
      height: 25px;
      position: absolute;
      animation: snore 5s ease-in-out infinite;
      transform: rotate(45deg);
      box-shadow: inset -4px -4px 4px rgba(0, 0, 0, 0.3);
    }

    .eyes {
      position: absolute;
      top: -30px;
      left: -30px;

      .eye-left,
      .eye-right {
        border: 4px solid #5c3191;
        width: 30px;
        height: 15px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        border-top: 0;
        position: absolute;

        &:before,
        &:after {
          content: "";
          position: absolute;
          border-radius: 50%;
          width: 4px;
          height: 4px;
          background-color: #5c3191;
          top: -2px;
          left: -4px;
        }

        &:after {
          left: auto;
          right: -4px;
        }
      }

      .eye-right {
        left: 50px;
      }
    }
  }
}


//keyframes
@keyframes rotate {
  0%, 100% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(0deg);
  }
}

@keyframes snore {
  0%, 100% {
    transform: scale(1) rotate(30deg);
  }
  50% {
    transform: scale(0.5) rotate(30deg);
    border-bottom-left-radius: 50%;
  }
}

@keyframes twinkle {
  0%, 100% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.3;
  }
}

@media screen and (max-width: 580px) {
  .number {
    font-size: 100px;
  }
  .moon {
    width: 100px;
    height: 100px;
  }
  .face {
    transform: scale(0.7)
  }
}
</style>