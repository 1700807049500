<template>
  <div class="wrapper">
    <div class="box outer"></div>
    <div class="box inner">
      <div class="envelope-container">
        <div class="envelope-back"></div>
        <div class="card-front"></div>
      </div>
    </div>
    <div class="box inner">
      <div class="envelope-container">
        <div class="envelope-back"></div>
        <div class="card-front"></div>
      </div>
    </div>
    <div class="box inner">
      <div class="envelope-container">
        <div class="envelope-back"></div>
        <div class="card-front"></div>
      </div>
    </div>
    <div class="box inner">
      <div class="envelope-container">
        <div class="envelope-back"></div>
        <div class="card-front"></div>
      </div>
    </div>
    <div class="box inner">
      <div class="envelope-container">
        <div class="envelope-back"></div>
        <div class="card-front"></div>
      </div>
    </div>
    <div class="box inner">
      <div class="envelope-container">
        <div class="envelope-back"></div>
        <div class="card-front"></div>
      </div>
    </div>
    <div class="box inner">
      <div class="envelope-container">
        <div class="envelope-back"></div>
        <div class="card-front"></div>
      </div>
    </div>
    <div class="box inner">
      <div class="envelope-container">
<!--        <input id="flap" ref="flap" type="checkbox">-->
        <label class="flap" for="flap"></label>
        <div class="envelope-back"></div>
        <div class="card-front"></div>
      </div>
    </div>
    <div class="shadow" :class="{'show': shadow}"/>
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  data() {
    return {
      shadow: false
    }
  },
  mounted() {
    gsap.from(".inner", {
      duration: 2,
      delay: .25,
      ease: "back.out",
      yPercent: -200,
      scale: 0,
      rotate: 360,
      stagger: {
        amount: 1,
      }
    });

    setTimeout(function () {
      this.shadow = true
    }.bind(this), 1000)
  }
}
</script>

<style scoped lang="scss">

$color1: #d1a4fd;
$color2: #dfd0ff;
$color3: #cebdf2;
$size: 30vmin;

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}


.shadow {
  display: inline-block;
  position: absolute;
  width: 200%;
  height: 130%;
  z-index: -1;
  transform: translate(-10%, -7%);
  background: radial-gradient(rgba(146, 64, 252, 0.4) 25%, rgba(0, 0, 0, 0) 60%);
  border-radius: 50%;
  opacity: 0;
  transition: opacity 1s ease-in;
}

.shadow.show {
  opacity: 1;
}

input#flap {
  display: none;
}

.envelope-container {
  position: relative;
}

.envelope-back {
  position: relative;
  width: 32vmin;
  height: 20vmin;
  background-color: $color1;
}

.card {
  position: absolute;
  width: 30vmin;
  height: 18vmin;
  background-color: white;
  top: 1vmin;
  left: 1vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4vmin;
  font-family: Brush Script MT;
  transition: .1s;
  z-index: 1;
  cursor: pointer;
  box-shadow: .1vmin -.1vminx .3vmin -.1vmin rgba(0, 0, 0, 0.2);
}

.card-front {
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 10vmin solid $color3;
  border-left: 16vmin solid transparent;
  border-right: 16vmin solid transparent;
  top: 10vmin;
  left: 0;
  z-index: 3;
}

.card-front:before, .card-front:after {
  content: "";
  position: absolute;
  border-bottom: 10vmin solid transparent;
  border-top: 10vmin solid transparent;
  height: 0;
  width: 0;
}

.card-front:before {
  border-left: 16.1vmin solid transparent;
  border-right: 16.1vmin solid $color2;
  top: -10vmin;
  left: -16.0vmin;
}

.card-front:after {
  border-right: 16.1vmin solid transparent;
  border-left: 16.1vmin solid $color2;
  top: -10vmin;
  left: -16vmin;
}

.flap {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 11vmin solid $color1;
  border-left: 16vmin solid transparent;
  border-right: 16vmin solid transparent;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: .3s;
  transform-origin: top;
  z-index: 4;
}

#flap:checked + .flap {
  transform: rotateX(180deg);
}

#flap:checked ~ .card-front {
  z-index: 7;
}

#flap:checked ~ .card {
  z-index: 4;
  transform: translateY(-6vmin);
}

.wrapper,
.box {
  position: absolute;
  margin-top: 3rem;
}

.inner:nth-child(2) {
  transform: scale(.2);
}

.inner:nth-child(3) {
  transform: scale(.3) rotate(45deg);
}

.inner:nth-child(4) {
  transform: scale(.4);
}

.inner:nth-child(5) {
  transform: scale(.5) rotate(15deg);
}

.inner:nth-child(6) {
  transform: scale(.6);
}

.inner:nth-child(7) {
  transform: scale(.7);
}

.inner:nth-child(8) {
  transform: scale(.8);
}


@media (max-width: 1199.98px) {
  .wrapper {
    transform: scale(0.7);
  }
}

@media (max-width: 767.98px) {
  .wrapper {
    transform: scale(1.5);
    top: 38%;
  }
}
</style>
