<template>
  <section class="PortfolioItem animate__animated animate__slow" :class="right ? 'right' : 'lft'">
    <div class="slider">
      <div class="slider_border_line"/>
      <carousel class="carousel" :items-to-show="1" :dir="right? 'rtl' : 'ltr'"  :wrap-around="true" :autoplay="2000" :pauseAutoplayOnHover="true">
        <slide class="slide"  v-for="(imgUrl, key) in project.img" :key="key">
          <img :src="imgUrl" draggable="false"/>
        </slide>
      </carousel>
    </div>
    <div class="content">
      <div>
        <h3>{{ project.title }}</h3>
        <p>{{ project.description }}</p>
      </div>
    </div>
  </section>
</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import HandleScrollMixin from "@/mixins/HandleScrollMixin";

export default {
  name: "PortfolioItem",
  props: {
    right: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object
    },
  },
  mixins: [HandleScrollMixin],
  components: {
    Carousel, Slide
  },
  methods: {
    onScroll: function () {
      this.handleScroll( '.PortfolioItem', 'animate__flipInX', 150, false)
    }
  },
  created () {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  },
}
</script>

<style lang="scss" scoped>

.PortfolioItem {
  display: flex;
  padding: 0;
  opacity: 0;

  &.animate__flipInX {
    opacity: 1;
  }

  h3 {
    font-size: 2vmin;
    text-transform: uppercase;
  }

  p {
    margin-top: 0.8vmin;
  }

  .slider {
    flex: 1;
    position: relative;

    .carousel {
      position: relative;
      user-select: none;
      width: 50vmin;
    }

    :deep(.carousel__viewport){
      box-shadow: 0 0 3vmin -1vmin rgba(1, 35, 80, 0.66);
    }

    img {
      width: 50vmin;
      height: 38vmin;
      object-fit: cover;
      object-position: center;
      display: inline-block;
      border: 1px solid  var(--color5);
    }

    .slider_border_line {
      content: "";
      position: absolute;
      top: 11%;
      bottom: 11%;
      left: 40%;
      right: -20%;
      border: 2px solid var(--color5);
    }
  }

  .content {
    flex: 2;
    margin: 1.5vmin 0; margin: 1.5vmin 0;
    padding: 7vmin 0;
    color: var(--color5);
    display: flex;

    div {
      max-width: 50vmin;
      padding: 1vmin 2vmin;
      transform: translate(8%);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &.right {
    flex-direction: row-reverse;

    .slider_border_line {
      left: -20%;
      right: 40%;
      border-left: unset;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 85%;
        background: black;
        width: 2px;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        top: 85%;
        background: black;
        width: 2px;
      }
    }

    .content {
      text-align: right;
      justify-content: flex-end;
      div {
        transform: translate(-8%);
      }
    }
  }

  &.lft {
    .slider_border_line {
      border-right: unset;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 85%;
        right: 0;
        background: black;
        width: 2px;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        top: 85%;
        right: 0;
        background: black;
        width: 2px;
      }
    }
  }

  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) {
   .content {
      flex: 1;
    }

   .slider {
      .carousel {
        width: 400px;
        margin: 0 auto;
      }

      img {
        width: 400px;
        height: 300px;
      }

     .slider_border_line {
       top: 10%;
       bottom: 10%;
     }
   }

    .content div {
        transform: translate(1%);
    }

    &.right .content div{
      transform: translate(-1%);
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {

    .slider {
      .carousel {
        width: 350px;
        margin: 0 auto;
      }

      img {
        width: 350px;
        height: 250px;
      }

      .slider_border_line {
        top: 10%;
        bottom: 10%;
      }
    }

    .slider_border_line {
      top: 5%;
      bottom: 5%;
    }

   .content {
      padding: 3vmin 0;
      h3 {
        font-size: 1.5rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    .content {

      div {
        max-width: unset;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1rem;
      }
    }

    &, &.right {
      flex-direction: column-reverse;
    }

    .slider_border_line {
      display: none;
    }

    .slider {
      .carousel {
        width: 80vw;
        padding-top: 0;
        padding-bottom: 3rem;
      }

      img {
        width: 80vw;
        height: 60vw;
      }
    }

   .content, &.right .content {
      padding: 0;
      margin: 0;
      text-align: center;
      justify-content: center;
      div {
        transform: unset;
      }
    }
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    & {
      padding-bottom: 2rem;
      h3 {
        font-size: 1.2rem;
      }
    }
  }
}

</style>