<template>
  <div class="home">
    <Header head_text="portfolio.header_title" content_text="portfolio.header_text" page="portfolio_page">
      <template v-slot:animation>
        <Animation/>
      </template>
    </Header>
    <PortfolioItems />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header_.vue'
import Animation from '@/components/portfolio_component/BookAnimation.vue';
import PortfolioItems from "@/components/portfolio_component/PortfolioItems";
import Footer from '@/components/Footer.vue';

export default {
  name: 'Home',
  components: {
     Header,PortfolioItems,Animation, Footer,
  },
}
</script>

