<template>
  <div class="FMap">
    <div>
      <YMap id="map"/>
      <Form/>
    </div>
  </div>
</template>

<script>
import Form from '@/components/contact_component/Form.vue'
import YMap from '@/components/contact_component/YMap.vue';

export default {
  name: "FMap",
  components: {
    Form,
    YMap
  }
}
</script>

<style lang="scss" scoped>
.FMap {
  div {
    display: flex;

    & > * {
      &:first-child {
        flex-shrink: 2;
        //order: 1;
      }

      &:last-child {
        flex-shrink: 3;
      }
    }
  }

  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) {
    #map {
      max-height: 75vh;
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    div {
      flex-direction: column-reverse;
    }
    #map {
      height: 35rem;
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    #map {
      height: 25rem;
    }
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
  }
}

</style>