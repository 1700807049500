<template>
    <div v-if="!hide" id="map" style=""></div>
</template>

<script>
export default {
  name: 'YMap',
  data() {
    return  {
      hide:false
    }
  },
  mounted() {
    try {
      this.map()
    } catch (e) {
      console.info( e.message)
      this.hide = true
    }
  },
  methods: {
    map: function () {

// Функция ymaps.ready() будет вызвана, когда
      // загрузятся все компоненты API, а также когда будет готово DOM-дерево.
      ymaps.ready(init);

      function init() {
        // Создание карты.
        var myMap = new ymaps.Map("map", {
          // Своё изображение иконки метки.
          //iconImageHref: 'https://picsum.photos/390/390', // Адрес до картинки
          // Размеры метки.
          iconImageSize: [60, 84],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          iconImageOffset: [-5, -38],
          // Координаты центра карты.
          // Порядок по умолчанию: «широта, долгота».
          // Чтобы не определять координаты центра карты вручную,
          // воспользуйтесь инструментом Определение координат.
          center: [40.207809, 44.529803],
          // Уровень масштабирования. Допустимые значения:
          // от 0 (весь мир) до 19.
          zoom: 17
        }, {
          searchControlProvider: 'yandex#search'
        });
        var myGeoObject = new ymaps.GeoObject({
          geometry: {
            type: "Point", // тип геометрии - точка
            coordinates: [40.207809, 44.529803] // координаты точки
          }
        });

        // Размещение геообъекта на карте.
        myMap.geoObjects.add(myGeoObject);
      }
    }

  },
};
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    height: 300px;
  }
}
</style>
