<template>
  <div class="form_section" @click="focused = false">
    <div class="container">
      <main-heading-block class="form_content_header">
        <h2>{{ $t('form_content_head.title') }}</h2>
        <p>{{ $t('form_content_head.description') }}</p>
      </main-heading-block>
      <Form v-slot="{ validate }"
            @submit="onSubmit"
            @invalid-submit="onInvalidSubmit"
            :validation-schema="schema"
            ref="form">
        <div class="form-row" @click.stop="triggerFocus('name')">
          <div :class="['form-field', {invalid: !!errors['name']}, {field: !!values['name']}, {focus: focused==='name'}]">
            <label>{{ $t('form_inputs.name') }}</label>
            <Field v-model="values['name']" id="name" name="name" maxlength="35"/>
            <error-message class="error-message" name="name"/>
          </div>
        </div>
        <div class="form-row" @click.stop="triggerFocus('email')">
          <div :class="['form-field', {invalid: !!errors['email']}, {field: !!values['email']}, {focus: focused==='email'}]">
            <label>{{ $t('form_inputs.email') }}</label>
            <Field v-model="values['email']" id="email" name="email" maxlength="35" type="email"/>
            <error-message class="error-message" name="email"/>
          </div>
        </div>
        <div class="form-row" @click.stop="triggerFocus('phone')">
          <div :class="['form-field', {invalid: !!errors['phone']}, {field: !!values['phone']}, {focus: focused==='phone'}]">
            <label>{{ $t('form_inputs.phone') }}</label>
            <Field v-model="values['phone']" id="phone" name="phone" minLength="8" maxlength="20" type="tel" @keydown="onKeyPress($event)"/>
<!--            <small>+(00) xxx xx xxx xxx</small>-->
            <error-message class="error-message" name="phone"/>
          </div>
        </div>
        <div class="form-row" @click.stop="triggerFocus('message')">
          <div :class="['form-field', {invalid: !!errors['message']}, {field: !!values['message']}, {focus: focused==='message'}]">
            <label>{{ $t('form_inputs.message') }}</label>
            <Field v-model="values['message']" id="message" name="message" as="textarea" rows="5" minLength="3" maxlength="500" type="tel"/>
            <error-message class="error-message" name="message"/>
          </div>
        </div>
<!--          <div class="form_check" @click="onClick">-->
<!--            <span :class="['checkbox', {'checked': values['confirm']}]"></span>-->
<!--            <span class="form_check_text">{{ $t('form_inputs.form_check_text') }}</span>-->
<!--          </div>-->
        <div class="form-row submit">
          <button class="btn light" :disabled="isDisabled">
            {{ $t('form_inputs.submit') }}
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>


import MainHeadingBlock from "@/components/blocks/MainHeadingBlock";
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import "yup-phone";
import axios from "axios";
import Swal from 'sweetalert2';

export default {
  name: "Form_",
  components: {
    MainHeadingBlock,
    ErrorMessage,
    Form,
    Field,
  },
  methods: {
    onSubmit: function () {
      this.isDisabled = true;
      axios.post("https://contact.l-it.am/email.php", {
        body: this.values
      },{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
          this.onSuccess()
      }).catch(error => {
          this.onFailure()
      })
    },
    onSuccess(){
        this.hasSuccess = true;
        this.hasError = false;

        this.toast.fire({
            icon: 'success',
            title: 'Success',
            text: 'Your message successfully sent!',
            timer: this.messageTimer,
        })
        setTimeout( () => {
            this.$refs.form.resetForm();
            this.isDisabled = false;
        }, this.messageTimer)
    },
    onFailure(){
        this.hasSuccess = false;
        this.hasError = true;

        this.toast.fire({
            icon: 'error',
            title: 'Error',
            text: 'Something went wrong, please try later.',
            timer: this.messageTimer,
        })

        setTimeout( () =>{
            this.isDisabled = false;
        }, this.messageTimer)
    },
    onInvalidSubmit: function ({values, errors, results}) {
      this.setErrors(errors);
    },
    triggerFocus(name) {
      this.focused = name;
      document.getElementById(name).focus();
    },
    setErrors: function (data) {
      this.errors = data;
    },
    onClick() {
      this.values['confirm'] = !this.values['confirm']
    },
    onKeyPress($e) {
      const theEvent = $e || window.event;
      let key;
      // Handle paste
      if (theEvent.type === 'paste') {
        key = event.clipboardData.getData('text/plain');
      } else {
        // Handle key press
        key = theEvent.keyCode || theEvent.which;

        if(key === 8) {
          return true;
        }
      }
      const regex = /^[0-9 ()+-_/s]+$/;
      if( !regex.test(theEvent.key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  },
  data() {
    const schema = yup.object({
      email: yup.string().required().email().min(6).max(35),
      name: yup.string().required().min(2).max(35),
      phone: yup.string().required().min(8).max(13),
      message: yup.string().required().min(8).max(500),
    });

    const fields = [
      {name: 'name'},
      {name: 'email'},
      {name: 'phone'},
      {
        name: 'message',
        as: 'textarea',
        rows: 5,
        keydown: 'keydown'
      }
    ];

    return {
      errors: {},
      values: {
        confirm: false
      },
      schema,
      focused: '',
      fields: fields,
      hasSuccess: false,
      hasError: false,
      isDisabled: false,
      messageTimer: 3000,
      toast: Swal.mixin({
        toast: true,
        iconColor: 'white',
        position: 'bottom-end',
        showConfirmButton: false,
        timerProgressBar: true,
        backdrop:false,
        customClass: {
          popup: 'colored-toast'
        },
       })
    }
  },
  watch: {
    'values.message':function () {
      this.errors['message'] = false
    },
    'values.phone':function (v) {
      this.errors['phone'] = false
    },
    'values.name':function () {
      this.errors['name'] = false
    },
    'values.email':function () {
      this.errors['email'] = false
    },
  }
}
</script>

<style lang="scss" >
.colored-toast.swal2-icon-error {
  background-color: var(--color-error) !important;
}
.colored-toast.swal2-icon-success {
  background-color: var(--color-success) !important;
}
.colored-toast .swal2-close {
  color: white;
}
.colored-toast .swal2-title {
  color: white;
  margin-bottom: 0!important;
  margin-left: 0.5rem!important;
  font-family: var(--font_texts) !important;
}
.colored-toast .swal2-html-container {
  color: white;
  font-size: 0.8rem!important;
  margin-left: 0.5rem!important;
  font-family: var(--font_texts) !important;
}
.swal2-popup.swal2-toast {
  padding: 0.5em 1em !important;
}

.form_section {
  background-image: linear-gradient(var(--bg) 55%, var(--color-head_r1) 80%), url(../../assets/svg/contact_head.svg);
  background-repeat: no-repeat;
  width: 100%;
  margin: 0 auto;
  padding: 5vmin;
  box-sizing: border-box;
}

.form-row {
  margin: 1vmin 0.5vmin;
}

.form_content_header {
  max-width: 80%;
  margin-bottom: 3vmin;
}

.form-field {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0.5vmin -0.25vmin var(--color3);
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0.4vmin;
  padding: 0.5vmin;
  position: relative;
  max-width: 100%;
  transition: all 0.4s;
  border-bottom: 0.5vmin solid rgba(0, 0, 0, 0);

  label {
    position: absolute;
    top: 0.5vmin;
    transition: all 0.2s;
    color: var(--texts_color);
    font-size: 1.8vmin;
    letter-spacing: 0.18vmin;
    font-family: var(--font-italic);
    font-weight: 500;
    padding-left: 1vmin;
    padding-top: 1vmin;
  }

  input, textarea {
    border: 0;
    outline: 0;
    resize: none;
    margin-top: 1.2vmin;
    font-style: italic;
    font-weight: 400;
    font-size: 2vmin;
    background-color: unset;
    color: var(--texts_color);
    padding: 1vmin 1vmin 0;
  }

  small {
    display: none;
    opacity: 0.4;
    padding: 0 1vmin;
    font-family: var(--font_texts);
    font-size: 1.3vmin;
  }

  span {
    align-self: flex-end;
    color: var(--color-error);
    position: absolute;
    bottom: 5px;
    opacity: 0.8;
    font-size: 1.4vmin;
    line-height: 1.4vmin;
    letter-spacing: 0.055em;
    font-weight: 300;
    font-family: var(--font_texts);
    padding-bottom: 0.3vmin;
    padding-right: 1vmin;
  }

  &.invalid {
    box-shadow: 0 0 4px var(--color-error);
  }

  &.focus {
    border-bottom: 0.2vmin solid var(--color4);
    small {
      display: block
    }
  }

  &.focus, &.field {
    transition: all 0.4s;

    label {
      font-size: 1.5vmin;
      padding-top: unset;
    }
  }

  :focus {
    outline: none;
  }
}

.am .form-field {
  label {
    font-family: serif;
    font-style: italic;
    font-size: 1.4vmin;
  }

  &.focus, &.field {
    label {
      font-size: 1.2vmin;
      padding-top: 0.2vmin;
    }
  }
}
.form_check {
  display: flex;
  align-items: end;
  cursor: pointer;
  font-family: var(--font_texts);
  color: var(--texts_color);
  font-size: 1.5vmin;

  .checkbox {
    min-width: 1.6vmin;
    height: 1.6vmin;
    border: 0.1vmin solid var(--texts_color);
    cursor: pointer;
    margin-right: 1vmin;
    margin-top: 0.5vmin;
    margin-left: 0.5vmin;
    border-radius: 15%;

    &.checked {
      border: 0.1vmin solid var(--bg);
      background: linear-gradient(265.11deg, var(--color-head_r1) -0.1%, var(--color4) 97.28%);
      box-shadow: 0 0 .4vmin rgba(0, 0, 0, 0.25);
      border-radius: 0.2vmin;
    }
  }
}

.submit {
  margin-top: 2.5vmin;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin-top: 0;
  }
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert.text-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert.text-danger {
  background-color: #ffb6b6;
  border-color: #ebccd1;
  color: #a94442;
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .form_section {
    padding: 5vmin 3vmin;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .form_section {
    padding: 5rem 3rem;
  }

  .form_content_header{
    margin-bottom: 3rem;
  }

  .form-row {
    margin: 1rem 0.5rem;
  }

  .form-field {
    display: flex;
    box-shadow: 0 0 0.4rem -0.2rem var(--color3);
    border-radius: 0.4vmin;
    padding: 0.5vmin;
    border-bottom: 0.5vmin solid rgba(0, 0, 0, 0);

    label {
      font-size: 1.3rem;
      padding-left: 0.5rem;
      padding-top:0.25rem;
    }

    input, textarea {
      margin-top: 0.5rem;
      font-size: 1rem;
      padding: 0.8rem 0.5rem 0;
    }

    span {
      bottom: 5px;
      font-size: 0.85rem;
      line-height: 0.85rem;
      letter-spacing: 0.055em;
      padding-bottom: 0;
      padding-right: 0.3rem;
    }

    &.invalid {
      box-shadow: 0 0 4px var(--color-error);
    }

    &.focus {
      border-bottom: 1px solid var(--color4);
    }

    &.focus, &.field {
      label {
        font-size: 0.8rem;
      }
    }
  }

  .form_check {
    font-size: 1rem;
  }

  .submit{
    margin-top: 2rem;
  }

  .am .form-field {
    label {
      font-family: serif;
      font-style: italic;
      font-size: 1rem;
    }

    &.focus, &.field {
      label {
        font-size: 0.8rem;
        padding-top: 0.2rem;
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .form_section {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>