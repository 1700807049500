<template>
  <section class="contacts_content">
    <div class="container">
      <main-heading-block>
        <h2 class="animate__animated animate__slow animate__flipInX">{{ $t('contacts_content_head.title') }}</h2>
        <p class="animate__animated animate__slow animate__fadeIn"> {{ $t('contacts_content_head.description') }} </p>
      </main-heading-block>
      <div class="contacts_content_blocs">
        <a v-for="content_bloc_item in this.$i18n.messages[this.$i18n.locale].contacts_content_bloc_items" :href="content_bloc_item.link">
          <div class="contacts_content_bloc_item animate__animated animate__slow">
            <img v-bind:src="content_bloc_item.icon" alt="tel" class="svg-tel" draggable="false">
            <h3>{{ content_bloc_item.title }}</h3>
            <p>{{ content_bloc_item.value }} </p>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import MainHeadingBlock from "@/components/blocks/MainHeadingBlock";
import HandleScrollMixin from "@/mixins/HandleScrollMixin";
export default {
  components: {MainHeadingBlock},
  mixins: [HandleScrollMixin],
  methods: {
    onScroll: function () {
      this.handleScroll( '.contacts_content_bloc_item', 'animate__fadeIn', 150, false)
    },
  },
  created () {
    window.addEventListener('scroll', this.onScroll)

  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped>

a {
  color: inherit;
  text-decoration: inherit;
}

.contacts_content_bloc_item{
  opacity: 0;
}

.contacts_content {
  width: 100%;
  margin: 0 auto;
}

.contacts_content_head {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacts_content_blocs {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
}

.contacts_content_bloc_item {
  margin: 3vmin 1vmin 0;
  & > *:not(:first-child) {
    margin-top: 2.5vmin;
  }
  & > *:last-child {
    margin-top: 0.5rem;
  }
}

.contacts_content_bloc_item > h3 {
  font-family: var(--font_head);
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--color5);
}

.contacts_content_bloc_item > p {
  font-family: var(--font_texts);
  font-style: italic;
  color: var(--texts_color);
  white-space: nowrap;
}

.contacts_content_bloc_item img {
  width: 20vmin;
  height: auto;
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .contacts_content_bloc_item {
    width: 30vw;
    margin: 3rem 1rem 0;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

  .contacts_content_bloc_item {
    width: 80vw;
    margin: 4rem 1rem 0;

    h3 {
      font-size: 1rem;
    }
    & > *:not(:first-child) {
      margin-top: 0.3rem;
    }

    img {
      width: 10rem;
    }
  }
}


</style>
