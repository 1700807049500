<template>
  <layout class="home">
    <Header head_text="contact_page" content_text="contact_content_text" page="contact_page">
      <template v-slot:animation>
        <Animation />
      </template>
    </Header>
    <Contacts />
    <FMap/>
  </layout>
</template>

<script>
import Header from '@/components/Header_.vue'
import Animation from '@/components/contact_component/Animation.vue';
import Contacts from '@/components/contact_component/Contacts.vue';
import Footer from '@/components/Footer.vue';
import FMap from "@/components/contact_component/FMap";
import Layout from "@/layouts/Layout";

export default {
  name: 'Home',
  components: {
    Layout,
    FMap, Header,Contacts,Animation, Footer
  },
}
</script>

