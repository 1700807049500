<template>
  <div>
    <Header head_text="not_found" content_text="" page="">
      <template v-slot:animation>
        <Animation/>
      </template>
    </Header>
    <div class="content">
      <div class="container">
        <img src="../assets/not_found/dribbble.gif">
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header_.vue';
import Footer from '@/components/Footer.vue';
import Animation from "@/components/not_found/Animation";

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Animation
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.content {
  img{
    width: 40vw;
    height: auto;
    margin: 0 auto;
    display: block;
  }
}
</style>