<template>
  <div>
    <section class="portfolio">
      <div class="container">
        <main-heading-block class="portfolio_heading animate__animated animate__slow animate__fadeIn">
          <h2>{{ $t('portfolio.portfolio_page_title') }} </h2>
          <p>{{ $t('portfolio.portfolio_page_text') }}</p>
        </main-heading-block>
        <PortfolioItem  class="item" v-for="(project, key) in this.$i18n.messages[this.$i18n.locale].portfolio.projects" :key="key" :project="project" :right="key%2 !== 0"/>
      </div>
    </section>
  </div>
</template>

<script>
import PortfolioItem from "@/components/portfolio_component/PortfolioItem";
import MainHeadingBlock from "@/components/blocks/MainHeadingBlock";

export default {
  components: {PortfolioItem, MainHeadingBlock},
  data() {
    return {}
  },
};
</script>

<style scoped>
.portfolio {
  background-color: var(--bg2);
  box-shadow: 0 0 6px -1px rgba(3, 16, 35, 0.2);
  background-image: url('../../assets/svg/circle.svg');
  background-size: 200%;
  background-position: center;
  overflow-x: hidden;
}
.portfolio_heading{
  margin-bottom: 5rem;
}

@media (max-width: 575.98px) {
  .item {
    margin-bottom: 3rem;
  }
}
</style>
